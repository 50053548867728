<template>
	<OfsPanel>
		<ListTable
			bordered
			hover
			:table-title="$t('Product Specifications')"
			:config="config"
			:items="productSpecifications.data"
			:fields="fields"
			:sort="sort"
			:total-items="productSpecifications.total"
			:current-page="currentPage"
			:per-page="perPage"
			:fetch-data="fetchData"
			:is-busy="isLoading"
			@table-change="handleTableChange"
			@row-clicked="handleRowClick"
		>
			<template slot="TableButtons-Slot-right" slot-scope="{}">
				<b-button variant="primary" class="ml-2" @click="handleAddClick">
					{{ $t('Create product specification') }}
				</b-button>
			</template>

			<template slot="TableButtons-Slot-left" slot-scope="{}">
				<OfFormInput
					:value="searchString"
					class="col-6 mr-2"
					:placeholder="$t('Search by product specification name')"
					@input="handleSearchStringInput"
				/>
			</template>

			<template v-slot:cell(updatedAt)="{ item }">
				{{ fromNow(item.updatedAt) }}
			</template>

			<template v-slot:cell(actions)="{ item }">
				<b-dropdown size="sm" toggle-class="p-1" right no-caret :disabled="isLoading">
					<template #button-content>
						<icon name="ellipsis-h" />
					</template>
					<b-dropdown-item @click.stop="removeProductSpecification(item._id)">
						<icon name="trash" class="mr-2" /> {{ $t('Delete') }}
					</b-dropdown-item>
				</b-dropdown>
			</template>
		</ListTable>
	</OfsPanel>
</template>

<script>
import moment from 'moment';
import { debounce } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { OfsPanel, ListTable, OfFormInput } from '@oneflow/ofs-vue-layout';
import withQuerystringState from '@/mixins/withQuerystringState';
import { parseDefaultQuerystringFilterValues } from '@/lib/parseDefaultQuerystringFilterValues';

const config = {
	refresh: { visible: true }
};

export default {
	components: {
		OfsPanel,
		ListTable,
		OfFormInput
	},
	mixins: [
		withQuerystringState([
			{ name: 'currentPage', defaultValue: 1, parseValue: Number },
			{ name: 'perPage', defaultValue: 10, parseValue: Number },
			'searchString',
			{ name: 'sort', defaultValue: {}, parseValue: value => value ?? {} },
			{ name: 'filterValues', parseValue: parseDefaultQuerystringFilterValues }
		])
	],
	data() {
		const fields = [
			{ key: 'name', label: this.$t('Name'), sortable: true },
			{ key: 'delivery', label: this.$t('Delivery'), sortable: true },
			{ key: 'updatedAt', label: this.$t('Last modified') },
			{ key: 'actions', label: this.$t('Actions'), sortable: false }
		];

		return {
			isLoading: false,
			fields,
			config: {
				refresh: { visible: true }
			}
		};
	},
	computed: {
		...mapGetters({
			productSpecifications: 'product-specification/product-specifications'
		})
	},
	async mounted() {
		await this.fetchData();
	},
	methods: {
		...mapActions({
			getProductSpecifications: 'product-specification/find',
			deleteProductSpecificationById: 'product-specification/deleteById'
		}),
		_map: _.map,
		fromNow(date) {
			return moment(date).fromNow();
		},
		async fetchData() {
			const query = {
				$limit: this.perPage,
				$skip: this.perPage * (this.currentPage - 1)
			};
			if (Object.keys(this.sort).length > 0) {
				query.$sort = this.sort;
			}
			if (this.searchString) {
				query.$or = [{ name: { $regex: this.searchString, $options: 'i' } }];
			}
			try {
				this.isLoading = true;
				await this.getProductSpecifications({ query: { query } });
			} catch (err) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('An error occurred while fetching product specifications')
				});

				throw err;
			} finally {
				this.isLoading = false;
			}
		},
		handleTableChange({ currentPage, perPage, sort }) {
			this.currentPage = currentPage;
			this.perPage = perPage;
			if (sort) {
				this.sort = sort;
			}
		},
		handleRowClick(item, index, event) {
			if (event.target.type === 'button') return;
			this.$router.push({ name: 'productSpecifications.edit', params: { id: item.id } });
		},
		handleAddClick() {
			this.$router.push({ name: 'productSpecifications.edit', params: { id: 'new' } });
		},
		handleSearchStringInput: debounce(function(value) {
			this.searchString = value;
			this.currentPage = 1;
			this.fetchData();
		}, 800),
		async confirmProductSpecificationRemove() {
			return this.$bvModal.msgBoxConfirm([this.$t('Are you sure to delete this product specification?')], {
				title: this.$t('Warning!'),
				okVariant: 'danger',
				size: 'md',
				centered: true
			});
		},
		async removeProductSpecification(productSpecificationId) {
			const approved = await this.confirmProductSpecificationRemove();
			if (approved) {
				await this.deleteProductSpecificationById({ id: productSpecificationId });
				this.fetchData();
			}
		}
	}
};
</script>
