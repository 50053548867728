import { $t } from '../../vuex';

export const operators = {
	ALWAYS: 'always',
	AND: 'and',
	OR: 'or',
	EQUAL: '==',
	STRICT_EQUAL: '===',
	NOT_EQUAL: '!=',
	IN: 'in',
	GT: '>',
	GTE: '>=',
	LT: '<',
	LTE: '<='
};

export const dataFieldType = {
	STRING: 'string',
	NUMBER: 'number'
};

export const logicOperatorOptions = [
	{ text: $t('Always'), value: operators.ALWAYS },
	{ text: $t('If all of the following apply'), value: operators.AND },
	{ text: $t('If any of the following apply'), value: operators.OR }
];

export const dataFieldOptions = [
	{ text: $t('Shipping Country'), value: 'order.shipping.isoCountry', type: dataFieldType.STRING },
	{ text: $t('Shipping State'), value: 'order.shipping.state', type: dataFieldType.STRING },
	{ text: $t('Shipping Method'), value: 'order.shippingMethod.alias', type: dataFieldType.STRING },
	{ text: $t('Store Region'), value: 'order.channel.sourceData.storeRegion', type: dataFieldType.STRING },
	{ text: $t('Order Currency'), value: 'order.isoCurrency', type: dataFieldType.STRING },
	{ text: $t('Item Quantity'), value: 'lineItem.quantity', type: dataFieldType.NUMBER },
	{ text: $t('Item Sale Price'), value: 'lineItem.salePrice', type: dataFieldType.NUMBER },
	{ text: $t('Order Promo Code'), value: 'order.discount.code', type: dataFieldType.STRING },
	{ text: $t('Item Delivery Type'), value: 'itemDeliveryType', type: dataFieldType.STRING }
];

export const productionRuleslogicOperatorOptions = [
	{ text: $t('If all of the following apply'), value: operators.AND },
	{ text: $t('If any of the following apply'), value: operators.OR }
];
