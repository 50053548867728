import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'product-specification',
		pluralName: 'product-specifications',
		transport: 'davinci',
		options: {
			namespaced: true
		}
	});
}
