<template>
	<OfMultiSelect :name="name" :label="label" :options="options" :multiple="multiple" />
</template>

<script>
import { OfMultiSelect } from '@oneflow/ofs-vue-layout';

export default {
	components: { OfMultiSelect },
	props: {
		label: {
			type: String,
			default() {
				return this.$t('Product Specification');
			}
		},
		name: {
			type: String,
			required: true
		},
		value: {
			type: null
		},
		multiple: {
			type: Boolean,
			default: false
		},
		productSpecifications: {
			type: Array,
			default: () => []
		}
	},
	computed: {
		options() {
			return this.productSpecifications?.map(spec => ({
				text: spec.name,
				value: spec.id
			}));
		}
	}
};
</script>
