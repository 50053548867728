import TemplatesList from './Templates/TemplatesList';
import TemplateEdit from './Templates/TemplateEdit';
import ProductsList from './Products/ProductsList';
import ProductEdit from './Products/ProductEdit';
import ProductSpecificationsList from './ProductSpecifications/ProductSpecificationsList';
import ProductSpecificationsEdit from './ProductSpecifications/ProductSpecificationsEdit';

const routes = [
	{
		path: '/create',
		name: 'create',
		redirect: '/products'
	},
	{
		name: 'templates.list',
		path: '/templates',
		component: TemplatesList
	},
	{
		name: 'templates.edit',
		path: '/templates/:id',
		component: TemplateEdit,
		meta: { layout: 'FullscreenLayout' }
	},
	{
		name: 'products.list',
		path: '/products',
		component: ProductsList
	},
	{
		name: 'products.edit',
		path: '/products/:id',
		component: ProductEdit
	},
	{
		name: 'products.add',
		path: '/products/:preset/:id',
		component: ProductEdit
	},
	{
		name: 'productSpecifications.list',
		path: '/product-specifications',
		component: ProductSpecificationsList
	},
	{
		name: 'productSpecifications.edit',
		path: '/product-specifications/:id',
		component: ProductSpecificationsEdit
	}
];

export default routes;
