<template>
	<b-form novalidate class="ProductSpecificationEdit-Form" @submit.prevent="onSave">
		<OfsPanel>
			<ContentHeader
				:title="productSpecificationId ? $t('Edit Product Specification') : $t('Create Product Specification')"
				class="mb-3"
				no-padding
			/>
			<div class="row">
				<div class="col-md-12">
					<OfFormInput name="name" :label="$t('Name (should be unique)')" required />
				</div>
			</div>
			<div class="row">
				<div class="col-md-6">
					<OfFormInput :label="$t('Sku')" name="sku" required show-errors />
				</div>
				<div class="col-md-4">
					<OfFormSelect
						name="delivery"
						:options="deliveryOptions"
						:placeholder="$t('none')"
						:label="$t('Delivery')"
						required
					/>
				</div>
			</div>

			<div v-if="isPhysical" class="row">
				<div class="col-6">
					<OfMultiSelect
						name="availablePSPSs"
						:label="$t('Available PSPs')"
						:options="psps.data"
						track-by="id"
						label-by="name"
						multiple
						required
					/>
				</div>
			</div>

			<br />

			<div v-if="isPhysical" class="row">
				<div class="col">
					<span class="text-capitalize mr-2 font-weight-bold">Production Rules*</span>
				</div>
				<div>
					<b-button variant="primary" @click="() => addProductionRule('productionRules')">
						{{ $t('Add Production Rule') }}
					</b-button>
				</div>
			</div>

			<br />

			<div v-if="isPhysical" class="row">
				<div class="col">
					<ProductionRule
						v-for="(rule, idx) in formData.productionRules"
						:key="`production-rule-${idx}`"
						:available-p-s-p-ss="formData.availablePSPSs"
						:rule="rule"
						:title="`${$t('Production Rule')} #${idx + 1}`"
						:form-path="`productionRules[${idx}]`"
						class="mb-2"
						@remove="removeProductionRule(idx, 'productionRules')"
					/>
				</div>
			</div>

			<template slot="actions">
				<of-submit-button v-t="'Save'" data-test-id="attribute-save" variant="primary" />
			</template>
		</OfsPanel>
	</b-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
	OfsPanel,
	ContentHeader,
	withForm,
	OfFormInput,
	OfSubmitButton,
	OfFormSelect,
	validateWithMessage,
	OfMultiSelect
} from '@oneflow/ofs-vue-layout';
import { $t } from '@/vuex';
import { codeSafe } from '@/lib/validators';
import { minValue, maxValue, minLength, maxLength } from 'vuelidate/lib/validators';
import ProductionRule from '@/components/JsonLogic/ProductionRule';

const initialFormData = {
	name: '',
	delivery: 'physical',
	availablePSPSs: [],
	productionRules: []
};

export default {
	components: {
		OfsPanel,
		ContentHeader,
		OfFormInput,
		OfSubmitButton,
		OfFormSelect,
		ProductionRule,
		OfMultiSelect
	},
	mixins: [withForm('ProductSpecificationForm')],
	computed: {
		...mapGetters({
			psps: 'psp/psps'
		}),
		productSpecificationId() {
			const { id } = this.$route.params;
			return id !== 'new' ? id : null;
		},
		deliveryOptions() {
			return ['Physical', 'Digital'].map(x => ({ text: x, value: x.toLowerCase() }));
		},
		isPhysical() {
			return this.formData.delivery === 'physical';
		}
	},
	async mounted() {
		try {
			await this.fetchPSPs({ query: { query: { $limit: 1000 } } });

			if (!this.productSpecificationId) {
				return this.initFormData(initialFormData);
			}

			const productSpecification = await this.findProductSpecificationById({
				id: this.productSpecificationId
			});

			this.initFormData(productSpecification);
		} catch (err) {
			this.$notify({
				type: 'error',
				title: this.$t('Error'),
				text: this.$t('An error occurred while fetching product specification')
			});
		}
	},
	methods: {
		...mapActions({
			findProductSpecificationById: 'product-specification/findById',
			createProductSpecification: 'product-specification/create',
			updateProductSpecification: 'product-specification/update',
			fetchPSPs: 'psp/find'
		}),
		async onSave() {
			try {
				let message = $t('Product specification updated successfully');
				if (this.formData._id) {
					await this.dispatchSubmit(this.updateProductSpecification({ id: this.formData._id, data: this.formData }));
				} else {
					message = $t('Product specification created successfully');
					const response = await this.dispatchSubmit(this.createProductSpecification(this.formData));

					this.updateField('_id', response._id);
					this.$router.push({
						name: 'productSpecifications.edit',
						params: { id: response._id }
					});
				}
				this.$notify({ type: 'success', text: message });
			} catch (err) {
				this.$notify({ type: 'error', text: err.message });
			}
		},
		addProductionRule(path) {
			const currentProductionRules = _.get(this.formData, path, []);
			const payoutRule = { if: [{ and: [true] }, '', ''] };

			this.updateField(path, [...currentProductionRules, payoutRule]);
		},
		removeProductionRule(index, path) {
			const currentProductionRules = _.get(this.formData, path, []);
			const filteredProductionRules = _.filter(currentProductionRules, (_, idx) => idx !== index);

			this.updateField(path, filteredProductionRules);
		}
	}
};
</script>
